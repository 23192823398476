<template>
  <public-tit></public-tit>
  <router-view></router-view>
  <public-foot></public-foot>
</template>

<script>
/**
* 1. $store-没有值[肯定没有]， 用本地mtk 获取新的用户缓存数据。
* 2. 没有本地 mtk， 保存非登录状态
 * 3. mtk查询过期， 返回清空本地mtk ，保存非登录状态
* */

import PublicTit from '@/components/publicTit.vue'
import PublicFoot from '@/components/publicFoot.vue'

export default {
  name: 'App',
  components: { PublicFoot, PublicTit },

}
</script>
