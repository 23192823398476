import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/jipd',
    name: 'jipd',
    meta: {
      title: '免费的机票单'
    },
    component: () => import(/* webpackChunkName: "jipd" */ '../views/jipd.vue')
  },
  {
    path: '/loginOut',
    name: 'loginOut',
    meta: {
      title: '退出登录'
    },
    component: () => import(/* webpackChunkName: "loginOut" */ '../components/loginOut.vue')
  },
]

// const router = createRouter({
//   routes,
//   history: createWebHistory(process.env.BASE_URL),
//   scrollBehavior() {
//     return { top: 0 }
//   }
// })
const router = createRouter({
  history: createWebHashHistory(),
  mode: 'hash', // 使用 hash 模式，才会在 URL 中显示 `#`
  routes
})
export default router
